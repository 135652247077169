<template>
  <div class="user-view">
    <div class="page-title d-flex justify-content-between align-items-center">
      <h3>Пользователи</h3>
      <button
          @click="openModal"
          class="btn btn-primary">Добавить пользователя
      </button>
    </div>
    <div class="page-content">
      <div class="table__sh-wrapper w-100 ml-3">
        <table class="table__sh w-100 border-0">

          <tr class="thead">
            <td>Ф.И.О</td>
            <td>Логин</td>
            <td>Номер телефона</td>
            <td>Роль</td>
            <td>Статус</td>
            <td>Processing Code</td>
            <td></td>
          </tr>

          <tr
              v-for="(item, itemIndex) in usersList"
              :key="itemIndex"
              @click="selectUser(item)"
              :class="activeUser(item.id) ? 'bg-grey' : ''"
              class="cursor-pointer"
          >
            <td >{{ item.fio }}</td>
            <td>{{ item.login }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ getNameById(roles, item.role, 'name') }}</td>
            <td>{{ getNameById(statuses, item.userStatus, 'name') }}</td>
            <td>{{item.processingCode}}</td>
            <td style="width: 150px">
              <div class="w-100 d-flex justify-content-center">
                <!--                <i class="action-icon">-->
                <!--                  <img src="../../../public/img/icon-eye.png" alt="">-->
                <!--                </i>-->
                <i @click="getUser(item)" class="action-icon">
                  <img src="../../../public/img/icon-pen.png" alt="">
                </i>
                <i v-if="item.userStatus === 2" @click="activateUser(item)" class="action-icon">
                  <img  src="../../../public/img/open-padlock.svg" alt="" class="trash-icon">
                </i>
                <i v-if="item.userStatus === 1" @click="deActivateUser(item)" class="action-icon">
                  <img src="../../../public/img/padlock.svg" alt="" class="trash-icon">
                </i>
<!--                <i class="action-icon">-->
<!--                  <img src="../../../public/img/icon-trash.svg" alt="" class="trash-icon">-->
<!--                </i>-->
              </div>
            </td>
          </tr>

        </table>
      </div>
      <div class="pagination-wrapper">
        <v-pagination
            v-if="pagination.totalCount > 1"
            v-model="pagination.page"
            :classes="paginationClasses"
            :page-count="pagination.totalCount"></v-pagination>
      </div>
      <div class="table__sh-wrapper w-100 ml-3 mb-2" v-if="selectedUser" >
        <div class="page-title d-flex justify-content-between align-items-center">
          <h3>{{ selectedUser.fio }}</h3>
          <button
              class="btn btn-primary"
              @click="addKey"
          >
            Добавить ключевых слов
          </button>
        </div>
        <table class="table__sh w-100 border-0" v-if="keywords.length > 0">
          <tr class="thead">
            <td>Key</td>
            <td>uz</td>
            <td>ru</td>
            <td>en</td>
            <td> isHidden </td>
            <td></td>
          </tr>
          <tr
              v-for="(item, itemIndex) in keywords"
              :key="itemIndex"
              :class="item.isHidden == true ? 'table__row-danger' : ''"
          >
            <td >{{ item.key }}</td>
            <td>{{ item.uz }}</td>
            <td>{{ item.ru }}</td>
            <td>{{ item.en }}</td>
            <td v-if="item.isHidden==true"><b-icon icon="check-square" scale="2" variant="danger"></b-icon> </td>
            <td v-else></td>
            <td style="width: 150px">
              <div class="w-100 d-flex justify-content-center">
<!--                                <i class="action-icon">-->
<!--                                  <img src="../../../public/img/icon-eye.png" alt="">-->
<!--                                </i>-->
                <i class="action-icon"  @click="showKey(item)" >
                  <img src="../../../public/img/icon-pen.png" alt="">
                </i>

                <i class="action-icon" @click="keywordsModalDelete=true">
                  <img src="../../../public/img/icon-trash.svg" alt="" class="trash-icon">
                </i>
              </div>
            </td>
          </tr>
        </table>
        <p v-else>Нет ключ пользователя</p>
      </div>
    </div>


    <!--  Modal  -->
    <b-modal v-model="modal"
             :title="update ? 'Изменить' : 'Добавить'"
             hide-footer>
      <div>
        <div class="form-group">
          <label>Ф.И.О</label>
          <b-form-input
              v-model="$v.user.fio.$model"
              :class="{'is-invalid': $v.user.fio.$error}"
              placeholder="Ф.И.О"></b-form-input>
        </div>
        <div class="form-group">
          <label>Номер телефона</label>
          <b-form-input
              v-model="$v.user.phone.$model"
              :class="{'is-invalid': $v.user.phone.$error}"
              placeholder="Номер телефона"></b-form-input>
        </div>
        <div class="form-group">
          <label>Роль</label>
          <b-form-select
              v-model="$v.user.role.$model"
              :class="{'is-invalid': $v.user.role.$error}"
              :options="roles"
              value-field="id"
              text-field="name"
          ></b-form-select>
        </div>
        <div class="form-group">
          <label>Call Back Url</label>
          <b-form-input
              v-model="$v.user.callBackUrl.$model"
              :class="{'is-invalid': $v.user.callBackUrl.$error}"
              placeholder="Call Back Url"></b-form-input>
        </div>
        <div class="form-group">
          <b-form-checkbox
              v-model="$v.user.isNeedToPrint.$model"
              :class="{'is-invalid': $v.user.isNeedToPrint.$error}"
          >
            Is Need To Print
          </b-form-checkbox>
        </div>
        <div class="form-group">
          <label>Логин</label>
          <b-form-input
              id="user-login"
              name="user-login"
              autocomplete="off"
              :disabled="update"
              readonly
              onfocus="this.removeAttribute('readonly')"
              v-model="$v.user.login.$model"
              :class="{'is-invalid': $v.user.login.$error}"
              placeholder="Логин">

          </b-form-input>
        </div>
        <div class="form-group">
          <label>Пароль</label>
          <b-form-input
              id="user-password"
              name="user-password"
              autocomplete="off"
              v-model="password"
              placeholder="Пароль" type="password"></b-form-input>
        </div>
        <div class="form-group">
          <label>Подтвердить пароль</label>
          <b-form-input
              v-model="$v.repeatPassword.$model"
              :class="{'is-invalid': $v.repeatPassword.$error}"
              placeholder="Подтвердить пароль" type="password"></b-form-input>
          <div class="error" v-if="!$v.repeatPassword.sameAsPassword">Passwords must be identical.</div>
        </div>
        <div class="form-group" v-if="update">
          <label>Billing Login</label>
          <b-form-input
              v-model="user.billingLogin"
              placeholder="Billing Login"></b-form-input>
        </div>
        <div class="form-group" v-if="update">
          <label>Billing Password</label>
          <b-form-input
              v-model="user.billingPassword"
              placeholder="Billing Password"></b-form-input>
        </div>
        <div class="form-group">
          <label>Processing Code</label>
          <b-form-input
              v-model="user.processingCode"
              placeholder="Processing Code"></b-form-input>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary mr-2" @click="submitUser">Сохранить</button>
          <button @click="modal= false" class="btn btn-secondary">Отменить</button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="keywordsModal"
             :title="updateKeyWords ? 'Изменить' : 'Добавить'"
             centered
             hide-footer
    >
      <div>
        <div class="form-group" v-if="selectedUser">
          <label>User</label>
          <b-form-input
              v-model="selectedUser.fio"
              placeholder="key"
              disabled
          ></b-form-input>
        </div>
        <div class="form-group">
          <label>Key</label>
          <b-form-input
              v-model="$v.formKeys.key.$model"
              :class="{'is-invalid': $v.formKeys.key.$error}"
              placeholder="key"></b-form-input>
        </div>
        <div class="form-group">
          <label>ruKey</label>
          <b-form-input
              v-model="formKeys.ru"
              placeholder="rukey"></b-form-input>
        </div>
        <div class="form-group">
          <label>uzKey</label>
          <b-form-input
              v-model="formKeys.uz"
              placeholder="uzKey"></b-form-input>
        </div>
        <div class="form-group">
          <label>enKey</label>
          <b-form-input
              v-model="formKeys.en"
              placeholder="enKey"></b-form-input>
        </div>
        <div class="form-group">
          <label>isHidden</label>
          <b-form-select
              v-model="formKeys.isHidden"
              :options="keyIsHidden"
              value-field="value"
              text-field="name"
          ></b-form-select>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary mr-2" @click="submitKey">Сохранить</button>
          <button @click="keywordsModal= false" class="btn btn-secondary">Отменить</button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="keywordsModalDelete"
             title="Вы уверены?"
             hide-footer
             centered
    >
      <div>
        <div class="model-body">
          <p> Вы действительно хотите удалить?</p>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button @click="keywordsModalDelete = false" class="btn btn-secondary mr-2">Отменить</button>
          <button class="btn btn-primary">Удалить</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {required, sameAs} from 'vuelidate/lib/validators'

export default {
  name: "Users",
  components: {},
  data() {
    return {
      pagination: {
        page: 1,
        count: 20,
        totalCount: 1
      },
      paginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      modal: false,
      update: false,
      usersList: [],
      user: {
        id: 0,
        fio: '',
        phone: '',
        login: '',
        role: 0,
        password: '',
        callBackUrl: '',
        isNeedToPrint: false,
        billingLogin: null,
        billingPassword: null,
        processingCode:null
      },
      password: '',
      repeatPassword: '',
      roles: [],
      statuses: [],
      selectedUser: null,
      formKeys:{
        id:0,
        ru:null,
        uz:null,
        en:null,
        key:null,
        isHidden:false,
        userId: null
      },
      keyIsHidden:[
        {
          name:'True',
          value:true
        },
        {
          name: 'False',
          value: false
        }
      ]

    ,
      keywordsModal:false,
      updateKeyWords:false,
      keywords:[],
      keywordsModalDelete:false,
    }
  },
  computed: {},
  methods: {
    async getUsers() {
      let params = {
        page: this.pagination.page,
        count: this.pagination.count,
      }
      try {
        this.showLoader();
        const res = await this.$store.dispatch('getUsers', params);
        this.usersList = res.result.users;
        this.pagination.totalCount = Math.ceil(res.result.count / 10);
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.hideLoader();
      }
    },
    async submitUser() {
      this.user.password = this.password;
      this.$v.user.$touch();
      if (!this.$v.user.$invalid) {
        try {
          this.showLoader();
          const res = await this.$http.post('Admin/SubmitUser', this.user);
          if (res) {
            await this.getUsers();
            this.modal = false;
            this.successNotify();
          }
        } catch (e) {
          this.errorNotify(e.response.data.error.message);
        } finally {
          this.hideLoader();
        }
      }
    },
    openModal() {
      this.resetUserForm();
      this.update = false;
      this.modal = true;
    },
    getUser(user) {
      this.user = {...user};
      this.update = true;
      this.modal = true;
    },
    async getRoles() {
      const res = await this.$store.dispatch('getRole');
      this.roles = res.result.roles;
    },
    async getStatuses() {
      const res = await this.$store.dispatch('getStatuses');
      this.statuses = res.result.userStatuses;
    },
    resetUserForm() {
      this.user.id = 0;
      this.user.fio = '';
      this.user.phone = '';
      this.user.login = '';
      this.user.role = 0;
      this.user.password = '';
      this.user.userStatus = 0;
      this.user.callBackUrl = '';
      this.user.isNeedToPrint = false;
      this.user.billingLogin = null;
      this.user.billingPassword = null;
      this.user.processingCode = '';
    },
    async activateUser(user) {
      try {
        const res = await this.$http.patch(`Admin/ActivateUser?userId=${user.id}`);
        if (res) {
          user.userStatus = 1;
          this.successNotify();
        }
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      }
    },
    async deActivateUser(user) {
      try {
        const res = await this.$http.delete(`Admin/DeactivateUser?userId=${user.id}`);
        if (res) {
          user.userStatus = 2;
          this.successNotify();
        }
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      }
    },
    selectUser(user) {
      this.selectedUser = user;
      this.formKeys.userId = user.id;
    },
    activeUser(id) {
      if (this.selectedUser) {
        return this.selectedUser.id === id
      }
    },
    async getKeywords(){
      this.showLoader();
      try {
        const res = await this.$http.get('Admin/GetKeywords', { params: { userId:  this.selectedUser.id }});
        this.keywords = res.result;
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.hideLoader();
      }
    },
    showKey(data){
      this.updateKeyWords = true;
      this.keywordsModal = true;
      this.formKeys.id = data.id;
      this.formKeys.key = data.key;
      this.formKeys.ru = data.ru;
      this.formKeys.uz = data.uz;
      this.formKeys.en = data.en;
      this.formKeys.isHidden = data.isHidden;
    },
    addKey(){
      this.keywordsModal = true;
      this.updateKeyWords = false;
      this.formKeys.id = 0;
      this.formKeys.key = null;
      this.formKeys.ru = null;
      this.formKeys.uz = null;
      this.formKeys.en = null;
      this.formKeys.isHidden = false;
    },
    async submitKey(){
        this.$v.formKeys.$touch();
        if (!this.$v.formKeys.$invalid) {
          try {
            const res = await this.$http.post('Admin/SubmitKeyword', this.formKeys);
            if(res) {
              await this.getKeywords();
              this.keywordsModal = false;
              this.successNotify();
            }
          } catch (e) {
            this.errorNotify(e.response.data.error.message);
          }
        }

    }

  },
  watch: {
    'pagination.page': function (val) {
      this.getUsers();
    },
    'selectedUser': function () {
      this.getKeywords();
    },
  },
  validations: {
    user: {
      fio: {required},
      phone: {required},
      login: {required},
      role: {required},
      callBackUrl: {required},
      isNeedToPrint: {required},
    },
    repeatPassword: {
      sameAsPassword: sameAs('password')
    },
    formKeys:{
      key: { required }
    }
  },
  created() {
    this.getUsers();
    this.getRoles();
    this.getStatuses();
  }
}
</script>

<style scoped>
.table__row-danger {
  position: relative;
}

.table__row-danger td:first-child:before {
  content: '';
  opacity: 0.5;
  box-shadow: inset 0 0 12px #ff0000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  border-radius: 10px;
  pointer-events: none;
}
 .form-control[readonly]{
   background-color: white!important;
 }
</style>
